import React from 'react';
import { LayoutStickyTool, PAGE_TYPE } from 'components/sticky-tool';
import PropTypes from 'prop-types';
import txcWcImg from './img/txc.jpg';
import './footer.less';

export function Footer(props) {
  const YEAR = new Date().getFullYear();

  return (
    <div className="home-footer">

      <LayoutStickyTool type={props.pageType || PAGE_TYPE.PRODUCTS} />

      <div className="cont-container">
        <div id="footer-menulist-pc">
          <div className="footer-menulist">
            <div className="footer-menulist-item">
              <dl>
                <dt>
                  产品支持
                </dt>
                <dd>
                  <a href="https://txc.qq.com/products/1368/faqs-more/" target="_self">帮助中心</a>
                </dd>
                <dd>
                  <a href="https://txc.qq.com/helper/fiveFaq" target="_self">接入文档</a>
                </dd>
                <dd>
                  <a href="https://support.qq.com/products/1368" target="_self">问题反馈</a>
                </dd>
                <dd>
                  <a href="https://txc.qq.com/products/1368/blog/506989" target="_self">更新日志</a>
                </dd>
              </dl>
            </div>
            <div className="footer-menulist-item">
              <dl>
                <dt>
                  友情链接
                </dt>
                <dd>
                  <a href="https://docs.qq.com/home" target="_self">腾讯文档</a>
                </dd>
                <dd>
                  <a href="https://lexiangla.com/home" target="_self">腾讯乐享</a>
                </dd>
                <dd>
                  <a href="https://www.tapd.cn/" target="_self">TAPD</a>
                </dd>
                <dd>
                  <a href="https://hiflow.tencent.com/?utm_source=yl&utm_medium=txclink" target="_self">腾讯云场景连接器</a>
                </dd>
              </dl>
            </div>
            <div className="footer-menulist-item">

            </div>
            <div className="footer-menulist-item">

            </div>
            <div className="footer-menulist-item">
              <dl>
                <dt>
                  生态工具
                </dt>
                <div className="dd-box">
                  <dd>
                    <a href="https://research.tencent.com/?from=txc" target="_self">调研云<span> - 专业的研究分析平台</span></a>
                  </dd>
                  <dd>
                    <a href="https://wj.qq.com/?utm_source=yl&utm_medium=txc" target="_self">腾讯问卷<span> - 免费的问卷调查系统</span></a>
                  </dd>
                  <dd>
                    <a href="https://mp.weixin.qq.com/s/YdESR8D7HQijqmYIIt1UgQ" target="_self">卷叔填填圈<span> - 有奖调研平台</span></a>
                  </dd>
                  <dd>
                    <a href="https://txc.qq.com/" target="_blank" rel="noreferrer">兔小巢<span> - 用户反馈搜集神器</span></a>
                  </dd>
                  <dd>
                    <a href="https://codesign.qq.com/" target="_blank" rel="noreferrer">Codesign<span> - 产品设计协同工具</span></a>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>

        <div className="footer-logolist">
          <a href="#!" className="coral-button coral-button--primary coral-button--size-default"><span className="logo"></span>
            <div className="wx-box">
              <span>关注腾讯兔小巢</span>
              <img src={txcWcImg} alt="" />
            </div></a>
          <a href="https://weibo.com/6273416519" className="coral-button coral-button--primary coral-button--size-default"><span className="logo"></span></a>
          <a href="mailto:txc@tencent.com" className="coral-button coral-button--primary coral-button--size-default"><span className="logo"></span></a>
        </div>

        <div id="copyright">
          <footer className="footer-copyright site-footer copyright relative">
            <div>
              Copyright &copy; 1998-{YEAR} Tencent. All Rights Reserved.
              <span className="item site-footer-link">腾讯公司版权所有</span>&nbsp;
              <a className="item site-footer-link" target="_blank" rel="noopener noreferrer" href="http://www.tencent.com/zh-cn/index.shtml">关于腾讯</a>
              <span className="item">|</span>
              <a className="item site-footer-link" target="_blank" rel="noopener noreferrer" href="http://www.qq.com/contract.shtml">服务条款</a>
              <span className="item">|</span>
              <a className="item site-footer-link" target="_blank" rel="noopener noreferrer" href="http://www.tencent.com/zh-cn/le/copyrightstatement.shtmll">版权说明</a>
              <span className="item">|</span>
              <a className="item site-footer-link" target="_blank" rel="noopener noreferrer" href="https://support.qq.com/products/1368/faqs-more/">常见问题</a>
            </div>
          </footer>
        </div>

      </div>
    </div>
  );
}

Footer.propTypes = {
  pageType: PropTypes.string,
};
